/**
 * Foundation for Sites
 * Version 6.7.5
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.f-box {
  /* Overides - Note: In future we want to load following in a modular way, also namespace colors */
  /* Helper to apply light color text with dark background */
  /* Foundation "select" tags have blank space without setting the width, this lets it take up any empty space */
  /* GRID TODO: See if we can set this through a mixin foundation provides */ }
  .f-box .grid-container {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto; }
    @media print, screen and (min-width: 40em) {
      .f-box .grid-container {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
    .f-box .grid-container.fluid {
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto; }
      @media print, screen and (min-width: 40em) {
        .f-box .grid-container.fluid {
          padding-right: 0.9375rem;
          padding-left: 0.9375rem; } }
    .f-box .grid-container.full {
      padding-right: 0;
      padding-left: 0;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto; }
  .f-box .grid-x {
    display: flex;
    flex-flow: row wrap; }
  .f-box .cell {
    flex: 0 0 auto;
    min-height: 0;
    min-width: 0;
    width: 100%; }
    .f-box .cell.auto {
      flex: 1 1 0; }
    .f-box .cell.shrink {
      flex: 0 0 auto; }
  .f-box .grid-x > .auto {
    width: auto; }
  .f-box .grid-x > .shrink {
    width: auto; }
  .f-box .grid-x > .small-shrink, .f-box .grid-x > .small-full, .f-box .grid-x > .small-1, .f-box .grid-x > .small-2, .f-box .grid-x > .small-3, .f-box .grid-x > .small-4, .f-box .grid-x > .small-5, .f-box .grid-x > .small-6, .f-box .grid-x > .small-7, .f-box .grid-x > .small-8, .f-box .grid-x > .small-9, .f-box .grid-x > .small-10, .f-box .grid-x > .small-11, .f-box .grid-x > .small-12 {
    flex-basis: auto; }
  @media print, screen and (min-width: 40em) {
    .f-box .grid-x > .medium-shrink, .f-box .grid-x > .medium-full, .f-box .grid-x > .medium-1, .f-box .grid-x > .medium-2, .f-box .grid-x > .medium-3, .f-box .grid-x > .medium-4, .f-box .grid-x > .medium-5, .f-box .grid-x > .medium-6, .f-box .grid-x > .medium-7, .f-box .grid-x > .medium-8, .f-box .grid-x > .medium-9, .f-box .grid-x > .medium-10, .f-box .grid-x > .medium-11, .f-box .grid-x > .medium-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 64em) {
    .f-box .grid-x > .large-shrink, .f-box .grid-x > .large-full, .f-box .grid-x > .large-1, .f-box .grid-x > .large-2, .f-box .grid-x > .large-3, .f-box .grid-x > .large-4, .f-box .grid-x > .large-5, .f-box .grid-x > .large-6, .f-box .grid-x > .large-7, .f-box .grid-x > .large-8, .f-box .grid-x > .large-9, .f-box .grid-x > .large-10, .f-box .grid-x > .large-11, .f-box .grid-x > .large-12 {
      flex-basis: auto; } }
  .f-box .grid-x > .small-1, .f-box .grid-x > .small-2, .f-box .grid-x > .small-3, .f-box .grid-x > .small-4, .f-box .grid-x > .small-5, .f-box .grid-x > .small-6, .f-box .grid-x > .small-7, .f-box .grid-x > .small-8, .f-box .grid-x > .small-9, .f-box .grid-x > .small-10, .f-box .grid-x > .small-11, .f-box .grid-x > .small-12 {
    flex: 0 0 auto; }
  .f-box .grid-x > .small-1 {
    width: 8.33333%; }
  .f-box .grid-x > .small-2 {
    width: 16.66667%; }
  .f-box .grid-x > .small-3 {
    width: 25%; }
  .f-box .grid-x > .small-4 {
    width: 33.33333%; }
  .f-box .grid-x > .small-5 {
    width: 41.66667%; }
  .f-box .grid-x > .small-6 {
    width: 50%; }
  .f-box .grid-x > .small-7 {
    width: 58.33333%; }
  .f-box .grid-x > .small-8 {
    width: 66.66667%; }
  .f-box .grid-x > .small-9 {
    width: 75%; }
  .f-box .grid-x > .small-10 {
    width: 83.33333%; }
  .f-box .grid-x > .small-11 {
    width: 91.66667%; }
  .f-box .grid-x > .small-12 {
    width: 100%; }
  @media print, screen and (min-width: 40em) {
    .f-box .grid-x > .medium-auto {
      flex: 1 1 0;
      width: auto; }
    .f-box .grid-x > .medium-shrink, .f-box .grid-x > .medium-1, .f-box .grid-x > .medium-2, .f-box .grid-x > .medium-3, .f-box .grid-x > .medium-4, .f-box .grid-x > .medium-5, .f-box .grid-x > .medium-6, .f-box .grid-x > .medium-7, .f-box .grid-x > .medium-8, .f-box .grid-x > .medium-9, .f-box .grid-x > .medium-10, .f-box .grid-x > .medium-11, .f-box .grid-x > .medium-12 {
      flex: 0 0 auto; }
    .f-box .grid-x > .medium-shrink {
      width: auto; }
    .f-box .grid-x > .medium-1 {
      width: 8.33333%; }
    .f-box .grid-x > .medium-2 {
      width: 16.66667%; }
    .f-box .grid-x > .medium-3 {
      width: 25%; }
    .f-box .grid-x > .medium-4 {
      width: 33.33333%; }
    .f-box .grid-x > .medium-5 {
      width: 41.66667%; }
    .f-box .grid-x > .medium-6 {
      width: 50%; }
    .f-box .grid-x > .medium-7 {
      width: 58.33333%; }
    .f-box .grid-x > .medium-8 {
      width: 66.66667%; }
    .f-box .grid-x > .medium-9 {
      width: 75%; }
    .f-box .grid-x > .medium-10 {
      width: 83.33333%; }
    .f-box .grid-x > .medium-11 {
      width: 91.66667%; }
    .f-box .grid-x > .medium-12 {
      width: 100%; } }
  @media print, screen and (min-width: 64em) {
    .f-box .grid-x > .large-auto {
      flex: 1 1 0;
      width: auto; }
    .f-box .grid-x > .large-shrink, .f-box .grid-x > .large-1, .f-box .grid-x > .large-2, .f-box .grid-x > .large-3, .f-box .grid-x > .large-4, .f-box .grid-x > .large-5, .f-box .grid-x > .large-6, .f-box .grid-x > .large-7, .f-box .grid-x > .large-8, .f-box .grid-x > .large-9, .f-box .grid-x > .large-10, .f-box .grid-x > .large-11, .f-box .grid-x > .large-12 {
      flex: 0 0 auto; }
    .f-box .grid-x > .large-shrink {
      width: auto; }
    .f-box .grid-x > .large-1 {
      width: 8.33333%; }
    .f-box .grid-x > .large-2 {
      width: 16.66667%; }
    .f-box .grid-x > .large-3 {
      width: 25%; }
    .f-box .grid-x > .large-4 {
      width: 33.33333%; }
    .f-box .grid-x > .large-5 {
      width: 41.66667%; }
    .f-box .grid-x > .large-6 {
      width: 50%; }
    .f-box .grid-x > .large-7 {
      width: 58.33333%; }
    .f-box .grid-x > .large-8 {
      width: 66.66667%; }
    .f-box .grid-x > .large-9 {
      width: 75%; }
    .f-box .grid-x > .large-10 {
      width: 83.33333%; }
    .f-box .grid-x > .large-11 {
      width: 91.66667%; }
    .f-box .grid-x > .large-12 {
      width: 100%; } }
  .f-box .grid-margin-x:not(.grid-x) > .cell {
    width: auto; }
  .f-box .grid-margin-y:not(.grid-y) > .cell {
    height: auto; }
  .f-box .grid-margin-x {
    margin-left: -0.625rem;
    margin-right: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .f-box .grid-margin-x {
        margin-left: -0.9375rem;
        margin-right: -0.9375rem; } }
    .f-box .grid-margin-x > .cell {
      width: calc(100% - 1.25rem);
      margin-left: 0.625rem;
      margin-right: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .f-box .grid-margin-x > .cell {
        width: calc(100% - 1.875rem);
        margin-left: 0.9375rem;
        margin-right: 0.9375rem; } }
    .f-box .grid-margin-x > .auto {
      width: auto; }
    .f-box .grid-margin-x > .shrink {
      width: auto; }
    .f-box .grid-margin-x > .small-1 {
      width: calc(8.33333% - 1.25rem); }
    .f-box .grid-margin-x > .small-2 {
      width: calc(16.66667% - 1.25rem); }
    .f-box .grid-margin-x > .small-3 {
      width: calc(25% - 1.25rem); }
    .f-box .grid-margin-x > .small-4 {
      width: calc(33.33333% - 1.25rem); }
    .f-box .grid-margin-x > .small-5 {
      width: calc(41.66667% - 1.25rem); }
    .f-box .grid-margin-x > .small-6 {
      width: calc(50% - 1.25rem); }
    .f-box .grid-margin-x > .small-7 {
      width: calc(58.33333% - 1.25rem); }
    .f-box .grid-margin-x > .small-8 {
      width: calc(66.66667% - 1.25rem); }
    .f-box .grid-margin-x > .small-9 {
      width: calc(75% - 1.25rem); }
    .f-box .grid-margin-x > .small-10 {
      width: calc(83.33333% - 1.25rem); }
    .f-box .grid-margin-x > .small-11 {
      width: calc(91.66667% - 1.25rem); }
    .f-box .grid-margin-x > .small-12 {
      width: calc(100% - 1.25rem); }
    @media print, screen and (min-width: 40em) {
      .f-box .grid-margin-x > .auto {
        width: auto; }
      .f-box .grid-margin-x > .shrink {
        width: auto; }
      .f-box .grid-margin-x > .small-1 {
        width: calc(8.33333% - 1.875rem); }
      .f-box .grid-margin-x > .small-2 {
        width: calc(16.66667% - 1.875rem); }
      .f-box .grid-margin-x > .small-3 {
        width: calc(25% - 1.875rem); }
      .f-box .grid-margin-x > .small-4 {
        width: calc(33.33333% - 1.875rem); }
      .f-box .grid-margin-x > .small-5 {
        width: calc(41.66667% - 1.875rem); }
      .f-box .grid-margin-x > .small-6 {
        width: calc(50% - 1.875rem); }
      .f-box .grid-margin-x > .small-7 {
        width: calc(58.33333% - 1.875rem); }
      .f-box .grid-margin-x > .small-8 {
        width: calc(66.66667% - 1.875rem); }
      .f-box .grid-margin-x > .small-9 {
        width: calc(75% - 1.875rem); }
      .f-box .grid-margin-x > .small-10 {
        width: calc(83.33333% - 1.875rem); }
      .f-box .grid-margin-x > .small-11 {
        width: calc(91.66667% - 1.875rem); }
      .f-box .grid-margin-x > .small-12 {
        width: calc(100% - 1.875rem); }
      .f-box .grid-margin-x > .medium-auto {
        width: auto; }
      .f-box .grid-margin-x > .medium-shrink {
        width: auto; }
      .f-box .grid-margin-x > .medium-1 {
        width: calc(8.33333% - 1.875rem); }
      .f-box .grid-margin-x > .medium-2 {
        width: calc(16.66667% - 1.875rem); }
      .f-box .grid-margin-x > .medium-3 {
        width: calc(25% - 1.875rem); }
      .f-box .grid-margin-x > .medium-4 {
        width: calc(33.33333% - 1.875rem); }
      .f-box .grid-margin-x > .medium-5 {
        width: calc(41.66667% - 1.875rem); }
      .f-box .grid-margin-x > .medium-6 {
        width: calc(50% - 1.875rem); }
      .f-box .grid-margin-x > .medium-7 {
        width: calc(58.33333% - 1.875rem); }
      .f-box .grid-margin-x > .medium-8 {
        width: calc(66.66667% - 1.875rem); }
      .f-box .grid-margin-x > .medium-9 {
        width: calc(75% - 1.875rem); }
      .f-box .grid-margin-x > .medium-10 {
        width: calc(83.33333% - 1.875rem); }
      .f-box .grid-margin-x > .medium-11 {
        width: calc(91.66667% - 1.875rem); }
      .f-box .grid-margin-x > .medium-12 {
        width: calc(100% - 1.875rem); } }
    @media print, screen and (min-width: 64em) {
      .f-box .grid-margin-x > .large-auto {
        width: auto; }
      .f-box .grid-margin-x > .large-shrink {
        width: auto; }
      .f-box .grid-margin-x > .large-1 {
        width: calc(8.33333% - 1.875rem); }
      .f-box .grid-margin-x > .large-2 {
        width: calc(16.66667% - 1.875rem); }
      .f-box .grid-margin-x > .large-3 {
        width: calc(25% - 1.875rem); }
      .f-box .grid-margin-x > .large-4 {
        width: calc(33.33333% - 1.875rem); }
      .f-box .grid-margin-x > .large-5 {
        width: calc(41.66667% - 1.875rem); }
      .f-box .grid-margin-x > .large-6 {
        width: calc(50% - 1.875rem); }
      .f-box .grid-margin-x > .large-7 {
        width: calc(58.33333% - 1.875rem); }
      .f-box .grid-margin-x > .large-8 {
        width: calc(66.66667% - 1.875rem); }
      .f-box .grid-margin-x > .large-9 {
        width: calc(75% - 1.875rem); }
      .f-box .grid-margin-x > .large-10 {
        width: calc(83.33333% - 1.875rem); }
      .f-box .grid-margin-x > .large-11 {
        width: calc(91.66667% - 1.875rem); }
      .f-box .grid-margin-x > .large-12 {
        width: calc(100% - 1.875rem); } }
  .f-box .grid-padding-x .grid-padding-x {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .f-box .grid-padding-x .grid-padding-x {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
  .grid-container:not(.full) > .f-box .grid-padding-x {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .grid-container:not(.full) > .f-box .grid-padding-x {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
  .f-box .grid-padding-x > .cell {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .f-box .grid-padding-x > .cell {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
  .f-box .small-up-1 > .cell {
    width: 100%; }
  .f-box .small-up-2 > .cell {
    width: 50%; }
  .f-box .small-up-3 > .cell {
    width: 33.33333%; }
  .f-box .small-up-4 > .cell {
    width: 25%; }
  .f-box .small-up-5 > .cell {
    width: 20%; }
  .f-box .small-up-6 > .cell {
    width: 16.66667%; }
  .f-box .small-up-7 > .cell {
    width: 14.28571%; }
  .f-box .small-up-8 > .cell {
    width: 12.5%; }
  @media print, screen and (min-width: 40em) {
    .f-box .medium-up-1 > .cell {
      width: 100%; }
    .f-box .medium-up-2 > .cell {
      width: 50%; }
    .f-box .medium-up-3 > .cell {
      width: 33.33333%; }
    .f-box .medium-up-4 > .cell {
      width: 25%; }
    .f-box .medium-up-5 > .cell {
      width: 20%; }
    .f-box .medium-up-6 > .cell {
      width: 16.66667%; }
    .f-box .medium-up-7 > .cell {
      width: 14.28571%; }
    .f-box .medium-up-8 > .cell {
      width: 12.5%; } }
  @media print, screen and (min-width: 64em) {
    .f-box .large-up-1 > .cell {
      width: 100%; }
    .f-box .large-up-2 > .cell {
      width: 50%; }
    .f-box .large-up-3 > .cell {
      width: 33.33333%; }
    .f-box .large-up-4 > .cell {
      width: 25%; }
    .f-box .large-up-5 > .cell {
      width: 20%; }
    .f-box .large-up-6 > .cell {
      width: 16.66667%; }
    .f-box .large-up-7 > .cell {
      width: 14.28571%; }
    .f-box .large-up-8 > .cell {
      width: 12.5%; } }
  .f-box .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.25rem); }
  .f-box .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.25rem); }
  .f-box .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 1.25rem); }
  .f-box .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.25rem); }
  .f-box .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.25rem); }
  .f-box .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 1.25rem); }
  .f-box .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 1.25rem); }
  .f-box .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .f-box .grid-margin-x.small-up-1 > .cell {
      width: calc(100% - 1.875rem); }
    .f-box .grid-margin-x.small-up-2 > .cell {
      width: calc(50% - 1.875rem); }
    .f-box .grid-margin-x.small-up-3 > .cell {
      width: calc(33.33333% - 1.875rem); }
    .f-box .grid-margin-x.small-up-4 > .cell {
      width: calc(25% - 1.875rem); }
    .f-box .grid-margin-x.small-up-5 > .cell {
      width: calc(20% - 1.875rem); }
    .f-box .grid-margin-x.small-up-6 > .cell {
      width: calc(16.66667% - 1.875rem); }
    .f-box .grid-margin-x.small-up-7 > .cell {
      width: calc(14.28571% - 1.875rem); }
    .f-box .grid-margin-x.small-up-8 > .cell {
      width: calc(12.5% - 1.875rem); }
    .f-box .grid-margin-x.medium-up-1 > .cell {
      width: calc(100% - 1.875rem); }
    .f-box .grid-margin-x.medium-up-2 > .cell {
      width: calc(50% - 1.875rem); }
    .f-box .grid-margin-x.medium-up-3 > .cell {
      width: calc(33.33333% - 1.875rem); }
    .f-box .grid-margin-x.medium-up-4 > .cell {
      width: calc(25% - 1.875rem); }
    .f-box .grid-margin-x.medium-up-5 > .cell {
      width: calc(20% - 1.875rem); }
    .f-box .grid-margin-x.medium-up-6 > .cell {
      width: calc(16.66667% - 1.875rem); }
    .f-box .grid-margin-x.medium-up-7 > .cell {
      width: calc(14.28571% - 1.875rem); }
    .f-box .grid-margin-x.medium-up-8 > .cell {
      width: calc(12.5% - 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .f-box .grid-margin-x.large-up-1 > .cell {
      width: calc(100% - 1.875rem); }
    .f-box .grid-margin-x.large-up-2 > .cell {
      width: calc(50% - 1.875rem); }
    .f-box .grid-margin-x.large-up-3 > .cell {
      width: calc(33.33333% - 1.875rem); }
    .f-box .grid-margin-x.large-up-4 > .cell {
      width: calc(25% - 1.875rem); }
    .f-box .grid-margin-x.large-up-5 > .cell {
      width: calc(20% - 1.875rem); }
    .f-box .grid-margin-x.large-up-6 > .cell {
      width: calc(16.66667% - 1.875rem); }
    .f-box .grid-margin-x.large-up-7 > .cell {
      width: calc(14.28571% - 1.875rem); }
    .f-box .grid-margin-x.large-up-8 > .cell {
      width: calc(12.5% - 1.875rem); } }
  .f-box .small-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .f-box .small-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; }
    .f-box .small-margin-collapse > .small-1 {
      width: 8.33333%; }
    .f-box .small-margin-collapse > .small-2 {
      width: 16.66667%; }
    .f-box .small-margin-collapse > .small-3 {
      width: 25%; }
    .f-box .small-margin-collapse > .small-4 {
      width: 33.33333%; }
    .f-box .small-margin-collapse > .small-5 {
      width: 41.66667%; }
    .f-box .small-margin-collapse > .small-6 {
      width: 50%; }
    .f-box .small-margin-collapse > .small-7 {
      width: 58.33333%; }
    .f-box .small-margin-collapse > .small-8 {
      width: 66.66667%; }
    .f-box .small-margin-collapse > .small-9 {
      width: 75%; }
    .f-box .small-margin-collapse > .small-10 {
      width: 83.33333%; }
    .f-box .small-margin-collapse > .small-11 {
      width: 91.66667%; }
    .f-box .small-margin-collapse > .small-12 {
      width: 100%; }
    @media print, screen and (min-width: 40em) {
      .f-box .small-margin-collapse > .medium-1 {
        width: 8.33333%; }
      .f-box .small-margin-collapse > .medium-2 {
        width: 16.66667%; }
      .f-box .small-margin-collapse > .medium-3 {
        width: 25%; }
      .f-box .small-margin-collapse > .medium-4 {
        width: 33.33333%; }
      .f-box .small-margin-collapse > .medium-5 {
        width: 41.66667%; }
      .f-box .small-margin-collapse > .medium-6 {
        width: 50%; }
      .f-box .small-margin-collapse > .medium-7 {
        width: 58.33333%; }
      .f-box .small-margin-collapse > .medium-8 {
        width: 66.66667%; }
      .f-box .small-margin-collapse > .medium-9 {
        width: 75%; }
      .f-box .small-margin-collapse > .medium-10 {
        width: 83.33333%; }
      .f-box .small-margin-collapse > .medium-11 {
        width: 91.66667%; }
      .f-box .small-margin-collapse > .medium-12 {
        width: 100%; } }
    @media print, screen and (min-width: 64em) {
      .f-box .small-margin-collapse > .large-1 {
        width: 8.33333%; }
      .f-box .small-margin-collapse > .large-2 {
        width: 16.66667%; }
      .f-box .small-margin-collapse > .large-3 {
        width: 25%; }
      .f-box .small-margin-collapse > .large-4 {
        width: 33.33333%; }
      .f-box .small-margin-collapse > .large-5 {
        width: 41.66667%; }
      .f-box .small-margin-collapse > .large-6 {
        width: 50%; }
      .f-box .small-margin-collapse > .large-7 {
        width: 58.33333%; }
      .f-box .small-margin-collapse > .large-8 {
        width: 66.66667%; }
      .f-box .small-margin-collapse > .large-9 {
        width: 75%; }
      .f-box .small-margin-collapse > .large-10 {
        width: 83.33333%; }
      .f-box .small-margin-collapse > .large-11 {
        width: 91.66667%; }
      .f-box .small-margin-collapse > .large-12 {
        width: 100%; } }
  .f-box .small-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .f-box .small-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; }
  @media print, screen and (min-width: 40em) {
    .f-box .medium-margin-collapse {
      margin-right: 0;
      margin-left: 0; }
      .f-box .medium-margin-collapse > .cell {
        margin-right: 0;
        margin-left: 0; } }
  @media print, screen and (min-width: 40em) {
    .f-box .medium-margin-collapse > .small-1 {
      width: 8.33333%; }
    .f-box .medium-margin-collapse > .small-2 {
      width: 16.66667%; }
    .f-box .medium-margin-collapse > .small-3 {
      width: 25%; }
    .f-box .medium-margin-collapse > .small-4 {
      width: 33.33333%; }
    .f-box .medium-margin-collapse > .small-5 {
      width: 41.66667%; }
    .f-box .medium-margin-collapse > .small-6 {
      width: 50%; }
    .f-box .medium-margin-collapse > .small-7 {
      width: 58.33333%; }
    .f-box .medium-margin-collapse > .small-8 {
      width: 66.66667%; }
    .f-box .medium-margin-collapse > .small-9 {
      width: 75%; }
    .f-box .medium-margin-collapse > .small-10 {
      width: 83.33333%; }
    .f-box .medium-margin-collapse > .small-11 {
      width: 91.66667%; }
    .f-box .medium-margin-collapse > .small-12 {
      width: 100%; } }
  @media print, screen and (min-width: 40em) {
    .f-box .medium-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .f-box .medium-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .f-box .medium-margin-collapse > .medium-3 {
      width: 25%; }
    .f-box .medium-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .f-box .medium-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .f-box .medium-margin-collapse > .medium-6 {
      width: 50%; }
    .f-box .medium-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .f-box .medium-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .f-box .medium-margin-collapse > .medium-9 {
      width: 75%; }
    .f-box .medium-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .f-box .medium-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .f-box .medium-margin-collapse > .medium-12 {
      width: 100%; } }
  @media print, screen and (min-width: 64em) {
    .f-box .medium-margin-collapse > .large-1 {
      width: 8.33333%; }
    .f-box .medium-margin-collapse > .large-2 {
      width: 16.66667%; }
    .f-box .medium-margin-collapse > .large-3 {
      width: 25%; }
    .f-box .medium-margin-collapse > .large-4 {
      width: 33.33333%; }
    .f-box .medium-margin-collapse > .large-5 {
      width: 41.66667%; }
    .f-box .medium-margin-collapse > .large-6 {
      width: 50%; }
    .f-box .medium-margin-collapse > .large-7 {
      width: 58.33333%; }
    .f-box .medium-margin-collapse > .large-8 {
      width: 66.66667%; }
    .f-box .medium-margin-collapse > .large-9 {
      width: 75%; }
    .f-box .medium-margin-collapse > .large-10 {
      width: 83.33333%; }
    .f-box .medium-margin-collapse > .large-11 {
      width: 91.66667%; }
    .f-box .medium-margin-collapse > .large-12 {
      width: 100%; } }
  @media print, screen and (min-width: 40em) {
    .f-box .medium-padding-collapse {
      margin-right: 0;
      margin-left: 0; }
      .f-box .medium-padding-collapse > .cell {
        padding-right: 0;
        padding-left: 0; } }
  @media print, screen and (min-width: 64em) {
    .f-box .large-margin-collapse {
      margin-right: 0;
      margin-left: 0; }
      .f-box .large-margin-collapse > .cell {
        margin-right: 0;
        margin-left: 0; } }
  @media print, screen and (min-width: 64em) {
    .f-box .large-margin-collapse > .small-1 {
      width: 8.33333%; }
    .f-box .large-margin-collapse > .small-2 {
      width: 16.66667%; }
    .f-box .large-margin-collapse > .small-3 {
      width: 25%; }
    .f-box .large-margin-collapse > .small-4 {
      width: 33.33333%; }
    .f-box .large-margin-collapse > .small-5 {
      width: 41.66667%; }
    .f-box .large-margin-collapse > .small-6 {
      width: 50%; }
    .f-box .large-margin-collapse > .small-7 {
      width: 58.33333%; }
    .f-box .large-margin-collapse > .small-8 {
      width: 66.66667%; }
    .f-box .large-margin-collapse > .small-9 {
      width: 75%; }
    .f-box .large-margin-collapse > .small-10 {
      width: 83.33333%; }
    .f-box .large-margin-collapse > .small-11 {
      width: 91.66667%; }
    .f-box .large-margin-collapse > .small-12 {
      width: 100%; } }
  @media print, screen and (min-width: 64em) {
    .f-box .large-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .f-box .large-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .f-box .large-margin-collapse > .medium-3 {
      width: 25%; }
    .f-box .large-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .f-box .large-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .f-box .large-margin-collapse > .medium-6 {
      width: 50%; }
    .f-box .large-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .f-box .large-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .f-box .large-margin-collapse > .medium-9 {
      width: 75%; }
    .f-box .large-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .f-box .large-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .f-box .large-margin-collapse > .medium-12 {
      width: 100%; } }
  @media print, screen and (min-width: 64em) {
    .f-box .large-margin-collapse > .large-1 {
      width: 8.33333%; }
    .f-box .large-margin-collapse > .large-2 {
      width: 16.66667%; }
    .f-box .large-margin-collapse > .large-3 {
      width: 25%; }
    .f-box .large-margin-collapse > .large-4 {
      width: 33.33333%; }
    .f-box .large-margin-collapse > .large-5 {
      width: 41.66667%; }
    .f-box .large-margin-collapse > .large-6 {
      width: 50%; }
    .f-box .large-margin-collapse > .large-7 {
      width: 58.33333%; }
    .f-box .large-margin-collapse > .large-8 {
      width: 66.66667%; }
    .f-box .large-margin-collapse > .large-9 {
      width: 75%; }
    .f-box .large-margin-collapse > .large-10 {
      width: 83.33333%; }
    .f-box .large-margin-collapse > .large-11 {
      width: 91.66667%; }
    .f-box .large-margin-collapse > .large-12 {
      width: 100%; } }
  @media print, screen and (min-width: 64em) {
    .f-box .large-padding-collapse {
      margin-right: 0;
      margin-left: 0; }
      .f-box .large-padding-collapse > .cell {
        padding-right: 0;
        padding-left: 0; } }
  .f-box .small-offset-0 {
    margin-left: 0%; }
  .f-box .grid-margin-x > .small-offset-0 {
    margin-left: calc(0% + 1.25rem / 2); }
  .f-box .small-offset-1 {
    margin-left: 8.33333%; }
  .f-box .grid-margin-x > .small-offset-1 {
    margin-left: calc(8.33333% + 1.25rem / 2); }
  .f-box .small-offset-2 {
    margin-left: 16.66667%; }
  .f-box .grid-margin-x > .small-offset-2 {
    margin-left: calc(16.66667% + 1.25rem / 2); }
  .f-box .small-offset-3 {
    margin-left: 25%; }
  .f-box .grid-margin-x > .small-offset-3 {
    margin-left: calc(25% + 1.25rem / 2); }
  .f-box .small-offset-4 {
    margin-left: 33.33333%; }
  .f-box .grid-margin-x > .small-offset-4 {
    margin-left: calc(33.33333% + 1.25rem / 2); }
  .f-box .small-offset-5 {
    margin-left: 41.66667%; }
  .f-box .grid-margin-x > .small-offset-5 {
    margin-left: calc(41.66667% + 1.25rem / 2); }
  .f-box .small-offset-6 {
    margin-left: 50%; }
  .f-box .grid-margin-x > .small-offset-6 {
    margin-left: calc(50% + 1.25rem / 2); }
  .f-box .small-offset-7 {
    margin-left: 58.33333%; }
  .f-box .grid-margin-x > .small-offset-7 {
    margin-left: calc(58.33333% + 1.25rem / 2); }
  .f-box .small-offset-8 {
    margin-left: 66.66667%; }
  .f-box .grid-margin-x > .small-offset-8 {
    margin-left: calc(66.66667% + 1.25rem / 2); }
  .f-box .small-offset-9 {
    margin-left: 75%; }
  .f-box .grid-margin-x > .small-offset-9 {
    margin-left: calc(75% + 1.25rem / 2); }
  .f-box .small-offset-10 {
    margin-left: 83.33333%; }
  .f-box .grid-margin-x > .small-offset-10 {
    margin-left: calc(83.33333% + 1.25rem / 2); }
  .f-box .small-offset-11 {
    margin-left: 91.66667%; }
  .f-box .grid-margin-x > .small-offset-11 {
    margin-left: calc(91.66667% + 1.25rem / 2); }
  @media print, screen and (min-width: 40em) {
    .f-box .medium-offset-0 {
      margin-left: 0%; }
    .f-box .grid-margin-x > .medium-offset-0 {
      margin-left: calc(0% + 1.875rem / 2); }
    .f-box .medium-offset-1 {
      margin-left: 8.33333%; }
    .f-box .grid-margin-x > .medium-offset-1 {
      margin-left: calc(8.33333% + 1.875rem / 2); }
    .f-box .medium-offset-2 {
      margin-left: 16.66667%; }
    .f-box .grid-margin-x > .medium-offset-2 {
      margin-left: calc(16.66667% + 1.875rem / 2); }
    .f-box .medium-offset-3 {
      margin-left: 25%; }
    .f-box .grid-margin-x > .medium-offset-3 {
      margin-left: calc(25% + 1.875rem / 2); }
    .f-box .medium-offset-4 {
      margin-left: 33.33333%; }
    .f-box .grid-margin-x > .medium-offset-4 {
      margin-left: calc(33.33333% + 1.875rem / 2); }
    .f-box .medium-offset-5 {
      margin-left: 41.66667%; }
    .f-box .grid-margin-x > .medium-offset-5 {
      margin-left: calc(41.66667% + 1.875rem / 2); }
    .f-box .medium-offset-6 {
      margin-left: 50%; }
    .f-box .grid-margin-x > .medium-offset-6 {
      margin-left: calc(50% + 1.875rem / 2); }
    .f-box .medium-offset-7 {
      margin-left: 58.33333%; }
    .f-box .grid-margin-x > .medium-offset-7 {
      margin-left: calc(58.33333% + 1.875rem / 2); }
    .f-box .medium-offset-8 {
      margin-left: 66.66667%; }
    .f-box .grid-margin-x > .medium-offset-8 {
      margin-left: calc(66.66667% + 1.875rem / 2); }
    .f-box .medium-offset-9 {
      margin-left: 75%; }
    .f-box .grid-margin-x > .medium-offset-9 {
      margin-left: calc(75% + 1.875rem / 2); }
    .f-box .medium-offset-10 {
      margin-left: 83.33333%; }
    .f-box .grid-margin-x > .medium-offset-10 {
      margin-left: calc(83.33333% + 1.875rem / 2); }
    .f-box .medium-offset-11 {
      margin-left: 91.66667%; }
    .f-box .grid-margin-x > .medium-offset-11 {
      margin-left: calc(91.66667% + 1.875rem / 2); } }
  @media print, screen and (min-width: 64em) {
    .f-box .large-offset-0 {
      margin-left: 0%; }
    .f-box .grid-margin-x > .large-offset-0 {
      margin-left: calc(0% + 1.875rem / 2); }
    .f-box .large-offset-1 {
      margin-left: 8.33333%; }
    .f-box .grid-margin-x > .large-offset-1 {
      margin-left: calc(8.33333% + 1.875rem / 2); }
    .f-box .large-offset-2 {
      margin-left: 16.66667%; }
    .f-box .grid-margin-x > .large-offset-2 {
      margin-left: calc(16.66667% + 1.875rem / 2); }
    .f-box .large-offset-3 {
      margin-left: 25%; }
    .f-box .grid-margin-x > .large-offset-3 {
      margin-left: calc(25% + 1.875rem / 2); }
    .f-box .large-offset-4 {
      margin-left: 33.33333%; }
    .f-box .grid-margin-x > .large-offset-4 {
      margin-left: calc(33.33333% + 1.875rem / 2); }
    .f-box .large-offset-5 {
      margin-left: 41.66667%; }
    .f-box .grid-margin-x > .large-offset-5 {
      margin-left: calc(41.66667% + 1.875rem / 2); }
    .f-box .large-offset-6 {
      margin-left: 50%; }
    .f-box .grid-margin-x > .large-offset-6 {
      margin-left: calc(50% + 1.875rem / 2); }
    .f-box .large-offset-7 {
      margin-left: 58.33333%; }
    .f-box .grid-margin-x > .large-offset-7 {
      margin-left: calc(58.33333% + 1.875rem / 2); }
    .f-box .large-offset-8 {
      margin-left: 66.66667%; }
    .f-box .grid-margin-x > .large-offset-8 {
      margin-left: calc(66.66667% + 1.875rem / 2); }
    .f-box .large-offset-9 {
      margin-left: 75%; }
    .f-box .grid-margin-x > .large-offset-9 {
      margin-left: calc(75% + 1.875rem / 2); }
    .f-box .large-offset-10 {
      margin-left: 83.33333%; }
    .f-box .grid-margin-x > .large-offset-10 {
      margin-left: calc(83.33333% + 1.875rem / 2); }
    .f-box .large-offset-11 {
      margin-left: 91.66667%; }
    .f-box .grid-margin-x > .large-offset-11 {
      margin-left: calc(91.66667% + 1.875rem / 2); } }
  .f-box .grid-y {
    display: flex;
    flex-flow: column nowrap; }
    .f-box .grid-y > .cell {
      height: auto;
      max-height: none; }
    .f-box .grid-y > .auto {
      height: auto; }
    .f-box .grid-y > .shrink {
      height: auto; }
    .f-box .grid-y > .small-shrink, .f-box .grid-y > .small-full, .f-box .grid-y > .small-1, .f-box .grid-y > .small-2, .f-box .grid-y > .small-3, .f-box .grid-y > .small-4, .f-box .grid-y > .small-5, .f-box .grid-y > .small-6, .f-box .grid-y > .small-7, .f-box .grid-y > .small-8, .f-box .grid-y > .small-9, .f-box .grid-y > .small-10, .f-box .grid-y > .small-11, .f-box .grid-y > .small-12 {
      flex-basis: auto; }
    @media print, screen and (min-width: 40em) {
      .f-box .grid-y > .medium-shrink, .f-box .grid-y > .medium-full, .f-box .grid-y > .medium-1, .f-box .grid-y > .medium-2, .f-box .grid-y > .medium-3, .f-box .grid-y > .medium-4, .f-box .grid-y > .medium-5, .f-box .grid-y > .medium-6, .f-box .grid-y > .medium-7, .f-box .grid-y > .medium-8, .f-box .grid-y > .medium-9, .f-box .grid-y > .medium-10, .f-box .grid-y > .medium-11, .f-box .grid-y > .medium-12 {
        flex-basis: auto; } }
    @media print, screen and (min-width: 64em) {
      .f-box .grid-y > .large-shrink, .f-box .grid-y > .large-full, .f-box .grid-y > .large-1, .f-box .grid-y > .large-2, .f-box .grid-y > .large-3, .f-box .grid-y > .large-4, .f-box .grid-y > .large-5, .f-box .grid-y > .large-6, .f-box .grid-y > .large-7, .f-box .grid-y > .large-8, .f-box .grid-y > .large-9, .f-box .grid-y > .large-10, .f-box .grid-y > .large-11, .f-box .grid-y > .large-12 {
        flex-basis: auto; } }
    .f-box .grid-y > .small-1, .f-box .grid-y > .small-2, .f-box .grid-y > .small-3, .f-box .grid-y > .small-4, .f-box .grid-y > .small-5, .f-box .grid-y > .small-6, .f-box .grid-y > .small-7, .f-box .grid-y > .small-8, .f-box .grid-y > .small-9, .f-box .grid-y > .small-10, .f-box .grid-y > .small-11, .f-box .grid-y > .small-12 {
      flex: 0 0 auto; }
    .f-box .grid-y > .small-1 {
      height: 8.33333%; }
    .f-box .grid-y > .small-2 {
      height: 16.66667%; }
    .f-box .grid-y > .small-3 {
      height: 25%; }
    .f-box .grid-y > .small-4 {
      height: 33.33333%; }
    .f-box .grid-y > .small-5 {
      height: 41.66667%; }
    .f-box .grid-y > .small-6 {
      height: 50%; }
    .f-box .grid-y > .small-7 {
      height: 58.33333%; }
    .f-box .grid-y > .small-8 {
      height: 66.66667%; }
    .f-box .grid-y > .small-9 {
      height: 75%; }
    .f-box .grid-y > .small-10 {
      height: 83.33333%; }
    .f-box .grid-y > .small-11 {
      height: 91.66667%; }
    .f-box .grid-y > .small-12 {
      height: 100%; }
    @media print, screen and (min-width: 40em) {
      .f-box .grid-y > .medium-auto {
        flex: 1 1 0;
        height: auto; }
      .f-box .grid-y > .medium-shrink, .f-box .grid-y > .medium-1, .f-box .grid-y > .medium-2, .f-box .grid-y > .medium-3, .f-box .grid-y > .medium-4, .f-box .grid-y > .medium-5, .f-box .grid-y > .medium-6, .f-box .grid-y > .medium-7, .f-box .grid-y > .medium-8, .f-box .grid-y > .medium-9, .f-box .grid-y > .medium-10, .f-box .grid-y > .medium-11, .f-box .grid-y > .medium-12 {
        flex: 0 0 auto; }
      .f-box .grid-y > .medium-shrink {
        height: auto; }
      .f-box .grid-y > .medium-1 {
        height: 8.33333%; }
      .f-box .grid-y > .medium-2 {
        height: 16.66667%; }
      .f-box .grid-y > .medium-3 {
        height: 25%; }
      .f-box .grid-y > .medium-4 {
        height: 33.33333%; }
      .f-box .grid-y > .medium-5 {
        height: 41.66667%; }
      .f-box .grid-y > .medium-6 {
        height: 50%; }
      .f-box .grid-y > .medium-7 {
        height: 58.33333%; }
      .f-box .grid-y > .medium-8 {
        height: 66.66667%; }
      .f-box .grid-y > .medium-9 {
        height: 75%; }
      .f-box .grid-y > .medium-10 {
        height: 83.33333%; }
      .f-box .grid-y > .medium-11 {
        height: 91.66667%; }
      .f-box .grid-y > .medium-12 {
        height: 100%; } }
    @media print, screen and (min-width: 64em) {
      .f-box .grid-y > .large-auto {
        flex: 1 1 0;
        height: auto; }
      .f-box .grid-y > .large-shrink, .f-box .grid-y > .large-1, .f-box .grid-y > .large-2, .f-box .grid-y > .large-3, .f-box .grid-y > .large-4, .f-box .grid-y > .large-5, .f-box .grid-y > .large-6, .f-box .grid-y > .large-7, .f-box .grid-y > .large-8, .f-box .grid-y > .large-9, .f-box .grid-y > .large-10, .f-box .grid-y > .large-11, .f-box .grid-y > .large-12 {
        flex: 0 0 auto; }
      .f-box .grid-y > .large-shrink {
        height: auto; }
      .f-box .grid-y > .large-1 {
        height: 8.33333%; }
      .f-box .grid-y > .large-2 {
        height: 16.66667%; }
      .f-box .grid-y > .large-3 {
        height: 25%; }
      .f-box .grid-y > .large-4 {
        height: 33.33333%; }
      .f-box .grid-y > .large-5 {
        height: 41.66667%; }
      .f-box .grid-y > .large-6 {
        height: 50%; }
      .f-box .grid-y > .large-7 {
        height: 58.33333%; }
      .f-box .grid-y > .large-8 {
        height: 66.66667%; }
      .f-box .grid-y > .large-9 {
        height: 75%; }
      .f-box .grid-y > .large-10 {
        height: 83.33333%; }
      .f-box .grid-y > .large-11 {
        height: 91.66667%; }
      .f-box .grid-y > .large-12 {
        height: 100%; } }
  .f-box .grid-padding-y .grid-padding-y {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .f-box .grid-padding-y .grid-padding-y {
        margin-top: -0.9375rem;
        margin-bottom: -0.9375rem; } }
  .f-box .grid-padding-y > .cell {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .f-box .grid-padding-y > .cell {
        padding-top: 0.9375rem;
        padding-bottom: 0.9375rem; } }
  .f-box .grid-margin-y {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .f-box .grid-margin-y {
        margin-top: -0.9375rem;
        margin-bottom: -0.9375rem; } }
    .f-box .grid-margin-y > .cell {
      height: calc(100% - 1.25rem);
      margin-top: 0.625rem;
      margin-bottom: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .f-box .grid-margin-y > .cell {
        height: calc(100% - 1.875rem);
        margin-top: 0.9375rem;
        margin-bottom: 0.9375rem; } }
    .f-box .grid-margin-y > .auto {
      height: auto; }
    .f-box .grid-margin-y > .shrink {
      height: auto; }
    .f-box .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.25rem); }
    .f-box .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.25rem); }
    .f-box .grid-margin-y > .small-3 {
      height: calc(25% - 1.25rem); }
    .f-box .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.25rem); }
    .f-box .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.25rem); }
    .f-box .grid-margin-y > .small-6 {
      height: calc(50% - 1.25rem); }
    .f-box .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.25rem); }
    .f-box .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.25rem); }
    .f-box .grid-margin-y > .small-9 {
      height: calc(75% - 1.25rem); }
    .f-box .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.25rem); }
    .f-box .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.25rem); }
    .f-box .grid-margin-y > .small-12 {
      height: calc(100% - 1.25rem); }
    @media print, screen and (min-width: 40em) {
      .f-box .grid-margin-y > .auto {
        height: auto; }
      .f-box .grid-margin-y > .shrink {
        height: auto; }
      .f-box .grid-margin-y > .small-1 {
        height: calc(8.33333% - 1.875rem); }
      .f-box .grid-margin-y > .small-2 {
        height: calc(16.66667% - 1.875rem); }
      .f-box .grid-margin-y > .small-3 {
        height: calc(25% - 1.875rem); }
      .f-box .grid-margin-y > .small-4 {
        height: calc(33.33333% - 1.875rem); }
      .f-box .grid-margin-y > .small-5 {
        height: calc(41.66667% - 1.875rem); }
      .f-box .grid-margin-y > .small-6 {
        height: calc(50% - 1.875rem); }
      .f-box .grid-margin-y > .small-7 {
        height: calc(58.33333% - 1.875rem); }
      .f-box .grid-margin-y > .small-8 {
        height: calc(66.66667% - 1.875rem); }
      .f-box .grid-margin-y > .small-9 {
        height: calc(75% - 1.875rem); }
      .f-box .grid-margin-y > .small-10 {
        height: calc(83.33333% - 1.875rem); }
      .f-box .grid-margin-y > .small-11 {
        height: calc(91.66667% - 1.875rem); }
      .f-box .grid-margin-y > .small-12 {
        height: calc(100% - 1.875rem); }
      .f-box .grid-margin-y > .medium-auto {
        height: auto; }
      .f-box .grid-margin-y > .medium-shrink {
        height: auto; }
      .f-box .grid-margin-y > .medium-1 {
        height: calc(8.33333% - 1.875rem); }
      .f-box .grid-margin-y > .medium-2 {
        height: calc(16.66667% - 1.875rem); }
      .f-box .grid-margin-y > .medium-3 {
        height: calc(25% - 1.875rem); }
      .f-box .grid-margin-y > .medium-4 {
        height: calc(33.33333% - 1.875rem); }
      .f-box .grid-margin-y > .medium-5 {
        height: calc(41.66667% - 1.875rem); }
      .f-box .grid-margin-y > .medium-6 {
        height: calc(50% - 1.875rem); }
      .f-box .grid-margin-y > .medium-7 {
        height: calc(58.33333% - 1.875rem); }
      .f-box .grid-margin-y > .medium-8 {
        height: calc(66.66667% - 1.875rem); }
      .f-box .grid-margin-y > .medium-9 {
        height: calc(75% - 1.875rem); }
      .f-box .grid-margin-y > .medium-10 {
        height: calc(83.33333% - 1.875rem); }
      .f-box .grid-margin-y > .medium-11 {
        height: calc(91.66667% - 1.875rem); }
      .f-box .grid-margin-y > .medium-12 {
        height: calc(100% - 1.875rem); } }
    @media print, screen and (min-width: 64em) {
      .f-box .grid-margin-y > .large-auto {
        height: auto; }
      .f-box .grid-margin-y > .large-shrink {
        height: auto; }
      .f-box .grid-margin-y > .large-1 {
        height: calc(8.33333% - 1.875rem); }
      .f-box .grid-margin-y > .large-2 {
        height: calc(16.66667% - 1.875rem); }
      .f-box .grid-margin-y > .large-3 {
        height: calc(25% - 1.875rem); }
      .f-box .grid-margin-y > .large-4 {
        height: calc(33.33333% - 1.875rem); }
      .f-box .grid-margin-y > .large-5 {
        height: calc(41.66667% - 1.875rem); }
      .f-box .grid-margin-y > .large-6 {
        height: calc(50% - 1.875rem); }
      .f-box .grid-margin-y > .large-7 {
        height: calc(58.33333% - 1.875rem); }
      .f-box .grid-margin-y > .large-8 {
        height: calc(66.66667% - 1.875rem); }
      .f-box .grid-margin-y > .large-9 {
        height: calc(75% - 1.875rem); }
      .f-box .grid-margin-y > .large-10 {
        height: calc(83.33333% - 1.875rem); }
      .f-box .grid-margin-y > .large-11 {
        height: calc(91.66667% - 1.875rem); }
      .f-box .grid-margin-y > .large-12 {
        height: calc(100% - 1.875rem); } }
  .f-box .grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .f-box .cell .grid-frame {
    width: 100%; }
  .f-box .cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .f-box .cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .f-box .cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .f-box .cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  @media print, screen and (min-width: 40em) {
    .f-box .medium-grid-frame {
      overflow: hidden;
      position: relative;
      flex-wrap: nowrap;
      align-items: stretch;
      width: 100vw; }
    .f-box .cell .medium-grid-frame {
      width: 100%; }
    .f-box .medium-cell-block {
      overflow-x: auto;
      max-width: 100%;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar; }
    .f-box .medium-cell-block-container {
      display: flex;
      flex-direction: column;
      max-height: 100%; }
      .f-box .medium-cell-block-container > .grid-x {
        max-height: 100%;
        flex-wrap: nowrap; }
    .f-box .medium-cell-block-y {
      overflow-y: auto;
      max-height: 100%;
      min-height: 100%;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar; } }
  @media print, screen and (min-width: 64em) {
    .f-box .large-grid-frame {
      overflow: hidden;
      position: relative;
      flex-wrap: nowrap;
      align-items: stretch;
      width: 100vw; }
    .f-box .cell .large-grid-frame {
      width: 100%; }
    .f-box .large-cell-block {
      overflow-x: auto;
      max-width: 100%;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar; }
    .f-box .large-cell-block-container {
      display: flex;
      flex-direction: column;
      max-height: 100%; }
      .f-box .large-cell-block-container > .grid-x {
        max-height: 100%;
        flex-wrap: nowrap; }
    .f-box .large-cell-block-y {
      overflow-y: auto;
      max-height: 100%;
      min-height: 100%;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar; } }
  .f-box .grid-y.grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh;
    width: auto; }
  @media print, screen and (min-width: 40em) {
    .f-box .grid-y.medium-grid-frame {
      overflow: hidden;
      position: relative;
      flex-wrap: nowrap;
      align-items: stretch;
      height: 100vh;
      width: auto; } }
  @media print, screen and (min-width: 64em) {
    .f-box .grid-y.large-grid-frame {
      overflow: hidden;
      position: relative;
      flex-wrap: nowrap;
      align-items: stretch;
      height: 100vh;
      width: auto; } }
  .f-box .cell .grid-y.grid-frame {
    height: 100%; }
  @media print, screen and (min-width: 40em) {
    .f-box .cell .grid-y.medium-grid-frame {
      height: 100%; } }
  @media print, screen and (min-width: 64em) {
    .f-box .cell .grid-y.large-grid-frame {
      height: 100%; } }
  .f-box .grid-margin-y {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .f-box .grid-margin-y {
        margin-top: -0.9375rem;
        margin-bottom: -0.9375rem; } }
    .f-box .grid-margin-y > .cell {
      height: calc(100% - 1.25rem);
      margin-top: 0.625rem;
      margin-bottom: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .f-box .grid-margin-y > .cell {
        height: calc(100% - 1.875rem);
        margin-top: 0.9375rem;
        margin-bottom: 0.9375rem; } }
    .f-box .grid-margin-y > .auto {
      height: auto; }
    .f-box .grid-margin-y > .shrink {
      height: auto; }
    .f-box .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.25rem); }
    .f-box .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.25rem); }
    .f-box .grid-margin-y > .small-3 {
      height: calc(25% - 1.25rem); }
    .f-box .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.25rem); }
    .f-box .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.25rem); }
    .f-box .grid-margin-y > .small-6 {
      height: calc(50% - 1.25rem); }
    .f-box .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.25rem); }
    .f-box .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.25rem); }
    .f-box .grid-margin-y > .small-9 {
      height: calc(75% - 1.25rem); }
    .f-box .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.25rem); }
    .f-box .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.25rem); }
    .f-box .grid-margin-y > .small-12 {
      height: calc(100% - 1.25rem); }
    @media print, screen and (min-width: 40em) {
      .f-box .grid-margin-y > .auto {
        height: auto; }
      .f-box .grid-margin-y > .shrink {
        height: auto; }
      .f-box .grid-margin-y > .small-1 {
        height: calc(8.33333% - 1.875rem); }
      .f-box .grid-margin-y > .small-2 {
        height: calc(16.66667% - 1.875rem); }
      .f-box .grid-margin-y > .small-3 {
        height: calc(25% - 1.875rem); }
      .f-box .grid-margin-y > .small-4 {
        height: calc(33.33333% - 1.875rem); }
      .f-box .grid-margin-y > .small-5 {
        height: calc(41.66667% - 1.875rem); }
      .f-box .grid-margin-y > .small-6 {
        height: calc(50% - 1.875rem); }
      .f-box .grid-margin-y > .small-7 {
        height: calc(58.33333% - 1.875rem); }
      .f-box .grid-margin-y > .small-8 {
        height: calc(66.66667% - 1.875rem); }
      .f-box .grid-margin-y > .small-9 {
        height: calc(75% - 1.875rem); }
      .f-box .grid-margin-y > .small-10 {
        height: calc(83.33333% - 1.875rem); }
      .f-box .grid-margin-y > .small-11 {
        height: calc(91.66667% - 1.875rem); }
      .f-box .grid-margin-y > .small-12 {
        height: calc(100% - 1.875rem); }
      .f-box .grid-margin-y > .medium-auto {
        height: auto; }
      .f-box .grid-margin-y > .medium-shrink {
        height: auto; }
      .f-box .grid-margin-y > .medium-1 {
        height: calc(8.33333% - 1.875rem); }
      .f-box .grid-margin-y > .medium-2 {
        height: calc(16.66667% - 1.875rem); }
      .f-box .grid-margin-y > .medium-3 {
        height: calc(25% - 1.875rem); }
      .f-box .grid-margin-y > .medium-4 {
        height: calc(33.33333% - 1.875rem); }
      .f-box .grid-margin-y > .medium-5 {
        height: calc(41.66667% - 1.875rem); }
      .f-box .grid-margin-y > .medium-6 {
        height: calc(50% - 1.875rem); }
      .f-box .grid-margin-y > .medium-7 {
        height: calc(58.33333% - 1.875rem); }
      .f-box .grid-margin-y > .medium-8 {
        height: calc(66.66667% - 1.875rem); }
      .f-box .grid-margin-y > .medium-9 {
        height: calc(75% - 1.875rem); }
      .f-box .grid-margin-y > .medium-10 {
        height: calc(83.33333% - 1.875rem); }
      .f-box .grid-margin-y > .medium-11 {
        height: calc(91.66667% - 1.875rem); }
      .f-box .grid-margin-y > .medium-12 {
        height: calc(100% - 1.875rem); } }
    @media print, screen and (min-width: 64em) {
      .f-box .grid-margin-y > .large-auto {
        height: auto; }
      .f-box .grid-margin-y > .large-shrink {
        height: auto; }
      .f-box .grid-margin-y > .large-1 {
        height: calc(8.33333% - 1.875rem); }
      .f-box .grid-margin-y > .large-2 {
        height: calc(16.66667% - 1.875rem); }
      .f-box .grid-margin-y > .large-3 {
        height: calc(25% - 1.875rem); }
      .f-box .grid-margin-y > .large-4 {
        height: calc(33.33333% - 1.875rem); }
      .f-box .grid-margin-y > .large-5 {
        height: calc(41.66667% - 1.875rem); }
      .f-box .grid-margin-y > .large-6 {
        height: calc(50% - 1.875rem); }
      .f-box .grid-margin-y > .large-7 {
        height: calc(58.33333% - 1.875rem); }
      .f-box .grid-margin-y > .large-8 {
        height: calc(66.66667% - 1.875rem); }
      .f-box .grid-margin-y > .large-9 {
        height: calc(75% - 1.875rem); }
      .f-box .grid-margin-y > .large-10 {
        height: calc(83.33333% - 1.875rem); }
      .f-box .grid-margin-y > .large-11 {
        height: calc(91.66667% - 1.875rem); }
      .f-box .grid-margin-y > .large-12 {
        height: calc(100% - 1.875rem); } }
  .f-box .grid-frame.grid-margin-y {
    height: calc(100vh + 1.25rem); }
    @media print, screen and (min-width: 40em) {
      .f-box .grid-frame.grid-margin-y {
        height: calc(100vh + 1.875rem); } }
    @media print, screen and (min-width: 64em) {
      .f-box .grid-frame.grid-margin-y {
        height: calc(100vh + 1.875rem); } }
  @media print, screen and (min-width: 40em) {
    .f-box .grid-margin-y.medium-grid-frame {
      height: calc(100vh + 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .f-box .grid-margin-y.large-grid-frame {
      height: calc(100vh + 1.875rem); } }
  .f-box [type='text'], .f-box [type='password'], .f-box [type='date'], .f-box [type='datetime'], .f-box [type='datetime-local'], .f-box [type='month'], .f-box [type='week'], .f-box [type='email'], .f-box [type='number'], .f-box [type='search'], .f-box [type='tel'], .f-box [type='time'], .f-box [type='url'], .f-box [type='color'],
  .f-box textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 2.4375rem;
    margin: 0 0 1rem;
    padding: 0.5rem;
    border: 1px solid #cacaca;
    border-radius: 0;
    background-color: #fefefe;
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
    font-family: inherit;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    color: #0a0a0a;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    appearance: none; }
    .f-box [type='text']:focus, .f-box [type='password']:focus, .f-box [type='date']:focus, .f-box [type='datetime']:focus, .f-box [type='datetime-local']:focus, .f-box [type='month']:focus, .f-box [type='week']:focus, .f-box [type='email']:focus, .f-box [type='number']:focus, .f-box [type='search']:focus, .f-box [type='tel']:focus, .f-box [type='time']:focus, .f-box [type='url']:focus, .f-box [type='color']:focus,
    .f-box textarea:focus {
      outline: none;
      border: 1px solid #8a8a8a;
      background-color: #fefefe;
      box-shadow: 0 0 5px #cacaca;
      transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  .f-box textarea {
    max-width: 100%; }
    .f-box textarea[rows] {
      height: auto; }
  .f-box input:disabled, .f-box input[readonly],
  .f-box textarea:disabled,
  .f-box textarea[readonly] {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  .f-box [type='submit'],
  .f-box [type='button'] {
    appearance: none;
    border-radius: 0; }
  .f-box input[type='search'] {
    box-sizing: border-box; }
  .f-box ::placeholder {
    color: #cacaca; }
  .f-box [type='file'],
  .f-box [type='checkbox'],
  .f-box [type='radio'] {
    margin: 0 0 1rem; }
  .f-box [type='checkbox'] + label,
  .f-box [type='radio'] + label {
    display: inline-block;
    vertical-align: baseline;
    margin-left: 0.5rem;
    margin-right: 1rem;
    margin-bottom: 0; }
    .f-box [type='checkbox'] + label[for],
    .f-box [type='radio'] + label[for] {
      cursor: pointer; }
  .f-box label > [type='checkbox'],
  .f-box label > [type='radio'] {
    margin-right: 0.5rem; }
  .f-box [type='file'] {
    width: 100%; }
  .f-box label {
    display: block;
    margin: 0;
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1.8;
    color: #0a0a0a; }
    .f-box label.middle {
      margin: 0 0 1rem;
      line-height: 1.5;
      padding: 0.5625rem 0; }
  .f-box .help-text {
    margin-top: -0.5rem;
    font-size: 0.8125rem;
    font-style: italic;
    color: #0a0a0a; }
  .f-box .input-group {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    align-items: stretch; }
    .f-box .input-group > :first-child, .f-box .input-group > :first-child.input-group-button > * {
      border-radius: 0 0 0 0; }
    .f-box .input-group > :last-child, .f-box .input-group > :last-child.input-group-button > * {
      border-radius: 0 0 0 0; }
  .f-box .input-group-label, .f-box .input-group-field, .f-box .input-group-button, .f-box .input-group-button a,
  .f-box .input-group-button input,
  .f-box .input-group-button button,
  .f-box .input-group-button label {
    margin: 0;
    white-space: nowrap; }
  .f-box .input-group-label {
    padding: 0 1rem;
    border: 1px solid #cacaca;
    background: #e6e6e6;
    color: #0a0a0a;
    text-align: center;
    white-space: nowrap;
    display: flex;
    flex: 0 0 auto;
    align-items: center; }
    .f-box .input-group-label:first-child {
      border-right: 0; }
    .f-box .input-group-label:last-child {
      border-left: 0; }
  .f-box .input-group-field {
    border-radius: 0;
    flex: 1 1 0px;
    min-width: 0; }
  .f-box .input-group-button {
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
    display: flex;
    flex: 0 0 auto; }
    .f-box .input-group-button a,
    .f-box .input-group-button input,
    .f-box .input-group-button button,
    .f-box .input-group-button label {
      align-self: stretch;
      height: auto;
      padding-top: 0;
      padding-bottom: 0;
      font-size: 1rem; }
  .f-box fieldset {
    margin: 0;
    padding: 0;
    border: 0; }
  .f-box legend {
    max-width: 100%;
    margin-bottom: 0.5rem; }
  .f-box .fieldset {
    margin: 1.125rem 0;
    padding: 1.25rem;
    border: 1px solid #cacaca; }
    .f-box .fieldset legend {
      margin: 0;
      margin-left: -0.1875rem;
      padding: 0 0.1875rem; }
  .f-box select {
    height: 2.4375rem;
    margin: 0 0 1rem;
    padding: 0.5rem;
    appearance: none;
    border: 1px solid #cacaca;
    border-radius: 0;
    background-color: #fefefe;
    font-family: inherit;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    color: #0a0a0a;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="24" viewBox="0 0 32 24"><polygon points="0,0 32,0 16,24" style="fill: rgb%28138, 138, 138%29"></polygon></svg>');
    background-origin: content-box;
    background-position: right -1rem center;
    background-repeat: no-repeat;
    background-size: 9px 6px;
    padding-right: 1.5rem;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
    @media screen and (min-width: 0\0) {
      .f-box select {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
    .f-box select:focus {
      outline: none;
      border: 1px solid #8a8a8a;
      background-color: #fefefe;
      box-shadow: 0 0 5px #cacaca;
      transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
    .f-box select:disabled {
      background-color: #e6e6e6;
      cursor: not-allowed; }
    .f-box select::-ms-expand {
      display: none; }
    .f-box select[multiple] {
      height: auto;
      background-image: none; }
    .f-box select:not([multiple]) {
      padding-top: 0;
      padding-bottom: 0; }
  .f-box .is-invalid-input:not(:focus) {
    border-color: #cc4b37;
    background-color: #f9ecea; }
    .f-box .is-invalid-input:not(:focus)::placeholder {
      color: #cc4b37; }
  .f-box .is-invalid-label {
    color: #cc4b37; }
  .f-box .form-error {
    display: none;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    font-size: 0.75rem;
    font-weight: bold;
    color: #cc4b37; }
    .f-box .form-error.is-visible {
      display: block; }
  .f-box .button {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 1rem 0;
    padding: 0.85em 1em;
    border: 1px solid transparent;
    border-radius: 0;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    font-family: inherit;
    font-size: 0.9rem;
    -webkit-appearance: none;
    line-height: 1;
    text-align: center;
    cursor: pointer; }
    [data-whatinput='mouse'] .f-box .button {
      outline: 0; }
    .f-box .button.tiny {
      font-size: 0.6rem; }
    .f-box .button.small {
      font-size: 0.75rem; }
    .f-box .button.large {
      font-size: 1.25rem; }
    .f-box .button.expanded {
      display: block;
      width: 100%;
      margin-right: 0;
      margin-left: 0; }
    .f-box .button, .f-box .button.disabled, .f-box .button[disabled], .f-box .button.disabled:hover, .f-box .button[disabled]:hover, .f-box .button.disabled:focus, .f-box .button[disabled]:focus {
      background-color: #1779ba;
      color: #fefefe; }
    .f-box .button:hover, .f-box .button:focus {
      background-color: #14679e;
      color: #fefefe; }
    .f-box .button.primary, .f-box .button.primary.disabled, .f-box .button.primary[disabled], .f-box .button.primary.disabled:hover, .f-box .button.primary[disabled]:hover, .f-box .button.primary.disabled:focus, .f-box .button.primary[disabled]:focus {
      background-color: #1779ba;
      color: #fefefe; }
    .f-box .button.primary:hover, .f-box .button.primary:focus {
      background-color: #126195;
      color: #fefefe; }
    .f-box .button.secondary, .f-box .button.secondary.disabled, .f-box .button.secondary[disabled], .f-box .button.secondary.disabled:hover, .f-box .button.secondary[disabled]:hover, .f-box .button.secondary.disabled:focus, .f-box .button.secondary[disabled]:focus {
      background-color: #767676;
      color: #fefefe; }
    .f-box .button.secondary:hover, .f-box .button.secondary:focus {
      background-color: #5e5e5e;
      color: #fefefe; }
    .f-box .button.success, .f-box .button.success.disabled, .f-box .button.success[disabled], .f-box .button.success.disabled:hover, .f-box .button.success[disabled]:hover, .f-box .button.success.disabled:focus, .f-box .button.success[disabled]:focus {
      background-color: #3adb76;
      color: #0a0a0a; }
    .f-box .button.success:hover, .f-box .button.success:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
    .f-box .button.warning, .f-box .button.warning.disabled, .f-box .button.warning[disabled], .f-box .button.warning.disabled:hover, .f-box .button.warning[disabled]:hover, .f-box .button.warning.disabled:focus, .f-box .button.warning[disabled]:focus {
      background-color: #ffae00;
      color: #0a0a0a; }
    .f-box .button.warning:hover, .f-box .button.warning:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
    .f-box .button.alert, .f-box .button.alert.disabled, .f-box .button.alert[disabled], .f-box .button.alert.disabled:hover, .f-box .button.alert[disabled]:hover, .f-box .button.alert.disabled:focus, .f-box .button.alert[disabled]:focus {
      background-color: #cc4b37;
      color: #fefefe; }
    .f-box .button.alert:hover, .f-box .button.alert:focus {
      background-color: #a53b2a;
      color: #fefefe; }
    .f-box .button.hollow, .f-box .button.hollow:hover, .f-box .button.hollow:focus, .f-box .button.hollow.disabled, .f-box .button.hollow.disabled:hover, .f-box .button.hollow.disabled:focus, .f-box .button.hollow[disabled], .f-box .button.hollow[disabled]:hover, .f-box .button.hollow[disabled]:focus {
      background-color: transparent; }
    .f-box .button.hollow, .f-box .button.hollow.disabled, .f-box .button.hollow[disabled], .f-box .button.hollow.disabled:hover, .f-box .button.hollow[disabled]:hover, .f-box .button.hollow.disabled:focus, .f-box .button.hollow[disabled]:focus {
      border: 1px solid #1779ba;
      color: #1779ba; }
    .f-box .button.hollow:hover, .f-box .button.hollow:focus {
      border-color: #0c3d5d;
      color: #0c3d5d; }
    .f-box .button.hollow.primary, .f-box .button.hollow.primary.disabled, .f-box .button.hollow.primary[disabled], .f-box .button.hollow.primary.disabled:hover, .f-box .button.hollow.primary[disabled]:hover, .f-box .button.hollow.primary.disabled:focus, .f-box .button.hollow.primary[disabled]:focus {
      border: 1px solid #1779ba;
      color: #1779ba; }
    .f-box .button.hollow.primary:hover, .f-box .button.hollow.primary:focus {
      border-color: #0c3d5d;
      color: #0c3d5d; }
    .f-box .button.hollow.secondary, .f-box .button.hollow.secondary.disabled, .f-box .button.hollow.secondary[disabled], .f-box .button.hollow.secondary.disabled:hover, .f-box .button.hollow.secondary[disabled]:hover, .f-box .button.hollow.secondary.disabled:focus, .f-box .button.hollow.secondary[disabled]:focus {
      border: 1px solid #767676;
      color: #767676; }
    .f-box .button.hollow.secondary:hover, .f-box .button.hollow.secondary:focus {
      border-color: #3b3b3b;
      color: #3b3b3b; }
    .f-box .button.hollow.success, .f-box .button.hollow.success.disabled, .f-box .button.hollow.success[disabled], .f-box .button.hollow.success.disabled:hover, .f-box .button.hollow.success[disabled]:hover, .f-box .button.hollow.success.disabled:focus, .f-box .button.hollow.success[disabled]:focus {
      border: 1px solid #3adb76;
      color: #3adb76; }
    .f-box .button.hollow.success:hover, .f-box .button.hollow.success:focus {
      border-color: #157539;
      color: #157539; }
    .f-box .button.hollow.warning, .f-box .button.hollow.warning.disabled, .f-box .button.hollow.warning[disabled], .f-box .button.hollow.warning.disabled:hover, .f-box .button.hollow.warning[disabled]:hover, .f-box .button.hollow.warning.disabled:focus, .f-box .button.hollow.warning[disabled]:focus {
      border: 1px solid #ffae00;
      color: #ffae00; }
    .f-box .button.hollow.warning:hover, .f-box .button.hollow.warning:focus {
      border-color: #805700;
      color: #805700; }
    .f-box .button.hollow.alert, .f-box .button.hollow.alert.disabled, .f-box .button.hollow.alert[disabled], .f-box .button.hollow.alert.disabled:hover, .f-box .button.hollow.alert[disabled]:hover, .f-box .button.hollow.alert.disabled:focus, .f-box .button.hollow.alert[disabled]:focus {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
    .f-box .button.hollow.alert:hover, .f-box .button.hollow.alert:focus {
      border-color: #67251a;
      color: #67251a; }
    .f-box .button.clear, .f-box .button.clear:hover, .f-box .button.clear:focus, .f-box .button.clear.disabled, .f-box .button.clear.disabled:hover, .f-box .button.clear.disabled:focus, .f-box .button.clear[disabled], .f-box .button.clear[disabled]:hover, .f-box .button.clear[disabled]:focus {
      border-color: transparent;
      background-color: transparent; }
    .f-box .button.clear, .f-box .button.clear.disabled, .f-box .button.clear[disabled], .f-box .button.clear.disabled:hover, .f-box .button.clear[disabled]:hover, .f-box .button.clear.disabled:focus, .f-box .button.clear[disabled]:focus {
      color: #1779ba; }
    .f-box .button.clear:hover, .f-box .button.clear:focus {
      color: #0c3d5d; }
    .f-box .button.clear.primary, .f-box .button.clear.primary.disabled, .f-box .button.clear.primary[disabled], .f-box .button.clear.primary.disabled:hover, .f-box .button.clear.primary[disabled]:hover, .f-box .button.clear.primary.disabled:focus, .f-box .button.clear.primary[disabled]:focus {
      color: #1779ba; }
    .f-box .button.clear.primary:hover, .f-box .button.clear.primary:focus {
      color: #0c3d5d; }
    .f-box .button.clear.secondary, .f-box .button.clear.secondary.disabled, .f-box .button.clear.secondary[disabled], .f-box .button.clear.secondary.disabled:hover, .f-box .button.clear.secondary[disabled]:hover, .f-box .button.clear.secondary.disabled:focus, .f-box .button.clear.secondary[disabled]:focus {
      color: #767676; }
    .f-box .button.clear.secondary:hover, .f-box .button.clear.secondary:focus {
      color: #3b3b3b; }
    .f-box .button.clear.success, .f-box .button.clear.success.disabled, .f-box .button.clear.success[disabled], .f-box .button.clear.success.disabled:hover, .f-box .button.clear.success[disabled]:hover, .f-box .button.clear.success.disabled:focus, .f-box .button.clear.success[disabled]:focus {
      color: #3adb76; }
    .f-box .button.clear.success:hover, .f-box .button.clear.success:focus {
      color: #157539; }
    .f-box .button.clear.warning, .f-box .button.clear.warning.disabled, .f-box .button.clear.warning[disabled], .f-box .button.clear.warning.disabled:hover, .f-box .button.clear.warning[disabled]:hover, .f-box .button.clear.warning.disabled:focus, .f-box .button.clear.warning[disabled]:focus {
      color: #ffae00; }
    .f-box .button.clear.warning:hover, .f-box .button.clear.warning:focus {
      color: #805700; }
    .f-box .button.clear.alert, .f-box .button.clear.alert.disabled, .f-box .button.clear.alert[disabled], .f-box .button.clear.alert.disabled:hover, .f-box .button.clear.alert[disabled]:hover, .f-box .button.clear.alert.disabled:focus, .f-box .button.clear.alert[disabled]:focus {
      color: #cc4b37; }
    .f-box .button.clear.alert:hover, .f-box .button.clear.alert:focus {
      color: #67251a; }
    .f-box .button.disabled, .f-box .button[disabled] {
      opacity: 0.25;
      cursor: not-allowed; }
    .f-box .button.dropdown::after {
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.4em;
      content: '';
      border-bottom-width: 0;
      border-color: #fefefe transparent transparent;
      position: relative;
      top: 0.4em;
      display: inline-block;
      float: right;
      margin-left: 1em; }
    .f-box .button.dropdown.hollow::after, .f-box .button.dropdown.clear::after {
      border-top-color: #1779ba; }
    .f-box .button.dropdown.hollow.primary::after, .f-box .button.dropdown.clear.primary::after {
      border-top-color: #1779ba; }
    .f-box .button.dropdown.hollow.secondary::after, .f-box .button.dropdown.clear.secondary::after {
      border-top-color: #767676; }
    .f-box .button.dropdown.hollow.success::after, .f-box .button.dropdown.clear.success::after {
      border-top-color: #3adb76; }
    .f-box .button.dropdown.hollow.warning::after, .f-box .button.dropdown.clear.warning::after {
      border-top-color: #ffae00; }
    .f-box .button.dropdown.hollow.alert::after, .f-box .button.dropdown.clear.alert::after {
      border-top-color: #cc4b37; }
    .f-box .button.arrow-only::after {
      top: -0.1em;
      float: none;
      margin-left: 0; }
  .f-box a.button:hover, .f-box a.button:focus {
    text-decoration: none; }
  .f-box .button-group {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex-grow: 1; }
    .f-box .button-group::before, .f-box .button-group::after {
      display: table;
      content: ' ';
      flex-basis: 0;
      order: 1; }
    .f-box .button-group::after {
      clear: both; }
    .f-box .button-group::before, .f-box .button-group::after {
      display: none; }
    .f-box .button-group .button {
      margin: 0;
      margin-right: 1px;
      margin-bottom: 1px;
      font-size: 0.9rem;
      flex: 0 0 auto; }
      .f-box .button-group .button:last-child {
        margin-right: 0; }
    .f-box .button-group.tiny .button {
      font-size: 0.6rem; }
    .f-box .button-group.small .button {
      font-size: 0.75rem; }
    .f-box .button-group.large .button {
      font-size: 1.25rem; }
    .f-box .button-group.expanded .button {
      flex: 1 1 0px; }
    .f-box .button-group.primary .button, .f-box .button-group.primary .button.disabled, .f-box .button-group.primary .button[disabled], .f-box .button-group.primary .button.disabled:hover, .f-box .button-group.primary .button[disabled]:hover, .f-box .button-group.primary .button.disabled:focus, .f-box .button-group.primary .button[disabled]:focus {
      background-color: #1779ba;
      color: #fefefe; }
    .f-box .button-group.primary .button:hover, .f-box .button-group.primary .button:focus {
      background-color: #126195;
      color: #fefefe; }
    .f-box .button-group.secondary .button, .f-box .button-group.secondary .button.disabled, .f-box .button-group.secondary .button[disabled], .f-box .button-group.secondary .button.disabled:hover, .f-box .button-group.secondary .button[disabled]:hover, .f-box .button-group.secondary .button.disabled:focus, .f-box .button-group.secondary .button[disabled]:focus {
      background-color: #767676;
      color: #fefefe; }
    .f-box .button-group.secondary .button:hover, .f-box .button-group.secondary .button:focus {
      background-color: #5e5e5e;
      color: #fefefe; }
    .f-box .button-group.success .button, .f-box .button-group.success .button.disabled, .f-box .button-group.success .button[disabled], .f-box .button-group.success .button.disabled:hover, .f-box .button-group.success .button[disabled]:hover, .f-box .button-group.success .button.disabled:focus, .f-box .button-group.success .button[disabled]:focus {
      background-color: #3adb76;
      color: #0a0a0a; }
    .f-box .button-group.success .button:hover, .f-box .button-group.success .button:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
    .f-box .button-group.warning .button, .f-box .button-group.warning .button.disabled, .f-box .button-group.warning .button[disabled], .f-box .button-group.warning .button.disabled:hover, .f-box .button-group.warning .button[disabled]:hover, .f-box .button-group.warning .button.disabled:focus, .f-box .button-group.warning .button[disabled]:focus {
      background-color: #ffae00;
      color: #0a0a0a; }
    .f-box .button-group.warning .button:hover, .f-box .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
    .f-box .button-group.alert .button, .f-box .button-group.alert .button.disabled, .f-box .button-group.alert .button[disabled], .f-box .button-group.alert .button.disabled:hover, .f-box .button-group.alert .button[disabled]:hover, .f-box .button-group.alert .button.disabled:focus, .f-box .button-group.alert .button[disabled]:focus {
      background-color: #cc4b37;
      color: #fefefe; }
    .f-box .button-group.alert .button:hover, .f-box .button-group.alert .button:focus {
      background-color: #a53b2a;
      color: #fefefe; }
    .f-box .button-group.hollow .button, .f-box .button-group.hollow .button:hover, .f-box .button-group.hollow .button:focus, .f-box .button-group.hollow .button.disabled, .f-box .button-group.hollow .button.disabled:hover, .f-box .button-group.hollow .button.disabled:focus, .f-box .button-group.hollow .button[disabled], .f-box .button-group.hollow .button[disabled]:hover, .f-box .button-group.hollow .button[disabled]:focus {
      background-color: transparent; }
    .f-box .button-group.hollow .button, .f-box .button-group.hollow .button.disabled, .f-box .button-group.hollow .button[disabled], .f-box .button-group.hollow .button.disabled:hover, .f-box .button-group.hollow .button[disabled]:hover, .f-box .button-group.hollow .button.disabled:focus, .f-box .button-group.hollow .button[disabled]:focus {
      border: 1px solid #1779ba;
      color: #1779ba; }
    .f-box .button-group.hollow .button:hover, .f-box .button-group.hollow .button:focus {
      border-color: #0c3d5d;
      color: #0c3d5d; }
    .f-box .button-group.hollow.primary .button, .f-box .button-group.hollow.primary .button.disabled, .f-box .button-group.hollow.primary .button[disabled], .f-box .button-group.hollow.primary .button.disabled:hover, .f-box .button-group.hollow.primary .button[disabled]:hover, .f-box .button-group.hollow.primary .button.disabled:focus, .f-box .button-group.hollow.primary .button[disabled]:focus, .f-box .button-group.hollow .button.primary, .f-box .button-group.hollow .button.primary.disabled, .f-box .button-group.hollow .button.primary[disabled], .f-box .button-group.hollow .button.primary.disabled:hover, .f-box .button-group.hollow .button.primary[disabled]:hover, .f-box .button-group.hollow .button.primary.disabled:focus, .f-box .button-group.hollow .button.primary[disabled]:focus {
      border: 1px solid #1779ba;
      color: #1779ba; }
    .f-box .button-group.hollow.primary .button:hover, .f-box .button-group.hollow.primary .button:focus, .f-box .button-group.hollow .button.primary:hover, .f-box .button-group.hollow .button.primary:focus {
      border-color: #0c3d5d;
      color: #0c3d5d; }
    .f-box .button-group.hollow.secondary .button, .f-box .button-group.hollow.secondary .button.disabled, .f-box .button-group.hollow.secondary .button[disabled], .f-box .button-group.hollow.secondary .button.disabled:hover, .f-box .button-group.hollow.secondary .button[disabled]:hover, .f-box .button-group.hollow.secondary .button.disabled:focus, .f-box .button-group.hollow.secondary .button[disabled]:focus, .f-box .button-group.hollow .button.secondary, .f-box .button-group.hollow .button.secondary.disabled, .f-box .button-group.hollow .button.secondary[disabled], .f-box .button-group.hollow .button.secondary.disabled:hover, .f-box .button-group.hollow .button.secondary[disabled]:hover, .f-box .button-group.hollow .button.secondary.disabled:focus, .f-box .button-group.hollow .button.secondary[disabled]:focus {
      border: 1px solid #767676;
      color: #767676; }
    .f-box .button-group.hollow.secondary .button:hover, .f-box .button-group.hollow.secondary .button:focus, .f-box .button-group.hollow .button.secondary:hover, .f-box .button-group.hollow .button.secondary:focus {
      border-color: #3b3b3b;
      color: #3b3b3b; }
    .f-box .button-group.hollow.success .button, .f-box .button-group.hollow.success .button.disabled, .f-box .button-group.hollow.success .button[disabled], .f-box .button-group.hollow.success .button.disabled:hover, .f-box .button-group.hollow.success .button[disabled]:hover, .f-box .button-group.hollow.success .button.disabled:focus, .f-box .button-group.hollow.success .button[disabled]:focus, .f-box .button-group.hollow .button.success, .f-box .button-group.hollow .button.success.disabled, .f-box .button-group.hollow .button.success[disabled], .f-box .button-group.hollow .button.success.disabled:hover, .f-box .button-group.hollow .button.success[disabled]:hover, .f-box .button-group.hollow .button.success.disabled:focus, .f-box .button-group.hollow .button.success[disabled]:focus {
      border: 1px solid #3adb76;
      color: #3adb76; }
    .f-box .button-group.hollow.success .button:hover, .f-box .button-group.hollow.success .button:focus, .f-box .button-group.hollow .button.success:hover, .f-box .button-group.hollow .button.success:focus {
      border-color: #157539;
      color: #157539; }
    .f-box .button-group.hollow.warning .button, .f-box .button-group.hollow.warning .button.disabled, .f-box .button-group.hollow.warning .button[disabled], .f-box .button-group.hollow.warning .button.disabled:hover, .f-box .button-group.hollow.warning .button[disabled]:hover, .f-box .button-group.hollow.warning .button.disabled:focus, .f-box .button-group.hollow.warning .button[disabled]:focus, .f-box .button-group.hollow .button.warning, .f-box .button-group.hollow .button.warning.disabled, .f-box .button-group.hollow .button.warning[disabled], .f-box .button-group.hollow .button.warning.disabled:hover, .f-box .button-group.hollow .button.warning[disabled]:hover, .f-box .button-group.hollow .button.warning.disabled:focus, .f-box .button-group.hollow .button.warning[disabled]:focus {
      border: 1px solid #ffae00;
      color: #ffae00; }
    .f-box .button-group.hollow.warning .button:hover, .f-box .button-group.hollow.warning .button:focus, .f-box .button-group.hollow .button.warning:hover, .f-box .button-group.hollow .button.warning:focus {
      border-color: #805700;
      color: #805700; }
    .f-box .button-group.hollow.alert .button, .f-box .button-group.hollow.alert .button.disabled, .f-box .button-group.hollow.alert .button[disabled], .f-box .button-group.hollow.alert .button.disabled:hover, .f-box .button-group.hollow.alert .button[disabled]:hover, .f-box .button-group.hollow.alert .button.disabled:focus, .f-box .button-group.hollow.alert .button[disabled]:focus, .f-box .button-group.hollow .button.alert, .f-box .button-group.hollow .button.alert.disabled, .f-box .button-group.hollow .button.alert[disabled], .f-box .button-group.hollow .button.alert.disabled:hover, .f-box .button-group.hollow .button.alert[disabled]:hover, .f-box .button-group.hollow .button.alert.disabled:focus, .f-box .button-group.hollow .button.alert[disabled]:focus {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
    .f-box .button-group.hollow.alert .button:hover, .f-box .button-group.hollow.alert .button:focus, .f-box .button-group.hollow .button.alert:hover, .f-box .button-group.hollow .button.alert:focus {
      border-color: #67251a;
      color: #67251a; }
    .f-box .button-group.clear .button, .f-box .button-group.clear .button:hover, .f-box .button-group.clear .button:focus, .f-box .button-group.clear .button.disabled, .f-box .button-group.clear .button.disabled:hover, .f-box .button-group.clear .button.disabled:focus, .f-box .button-group.clear .button[disabled], .f-box .button-group.clear .button[disabled]:hover, .f-box .button-group.clear .button[disabled]:focus {
      border-color: transparent;
      background-color: transparent; }
    .f-box .button-group.clear .button, .f-box .button-group.clear .button.disabled, .f-box .button-group.clear .button[disabled], .f-box .button-group.clear .button.disabled:hover, .f-box .button-group.clear .button[disabled]:hover, .f-box .button-group.clear .button.disabled:focus, .f-box .button-group.clear .button[disabled]:focus {
      color: #1779ba; }
    .f-box .button-group.clear .button:hover, .f-box .button-group.clear .button:focus {
      color: #0c3d5d; }
    .f-box .button-group.clear.primary .button, .f-box .button-group.clear.primary .button.disabled, .f-box .button-group.clear.primary .button[disabled], .f-box .button-group.clear.primary .button.disabled:hover, .f-box .button-group.clear.primary .button[disabled]:hover, .f-box .button-group.clear.primary .button.disabled:focus, .f-box .button-group.clear.primary .button[disabled]:focus, .f-box .button-group.clear .button.primary, .f-box .button-group.clear .button.primary.disabled, .f-box .button-group.clear .button.primary[disabled], .f-box .button-group.clear .button.primary.disabled:hover, .f-box .button-group.clear .button.primary[disabled]:hover, .f-box .button-group.clear .button.primary.disabled:focus, .f-box .button-group.clear .button.primary[disabled]:focus {
      color: #1779ba; }
    .f-box .button-group.clear.primary .button:hover, .f-box .button-group.clear.primary .button:focus, .f-box .button-group.clear .button.primary:hover, .f-box .button-group.clear .button.primary:focus {
      color: #0c3d5d; }
    .f-box .button-group.clear.secondary .button, .f-box .button-group.clear.secondary .button.disabled, .f-box .button-group.clear.secondary .button[disabled], .f-box .button-group.clear.secondary .button.disabled:hover, .f-box .button-group.clear.secondary .button[disabled]:hover, .f-box .button-group.clear.secondary .button.disabled:focus, .f-box .button-group.clear.secondary .button[disabled]:focus, .f-box .button-group.clear .button.secondary, .f-box .button-group.clear .button.secondary.disabled, .f-box .button-group.clear .button.secondary[disabled], .f-box .button-group.clear .button.secondary.disabled:hover, .f-box .button-group.clear .button.secondary[disabled]:hover, .f-box .button-group.clear .button.secondary.disabled:focus, .f-box .button-group.clear .button.secondary[disabled]:focus {
      color: #767676; }
    .f-box .button-group.clear.secondary .button:hover, .f-box .button-group.clear.secondary .button:focus, .f-box .button-group.clear .button.secondary:hover, .f-box .button-group.clear .button.secondary:focus {
      color: #3b3b3b; }
    .f-box .button-group.clear.success .button, .f-box .button-group.clear.success .button.disabled, .f-box .button-group.clear.success .button[disabled], .f-box .button-group.clear.success .button.disabled:hover, .f-box .button-group.clear.success .button[disabled]:hover, .f-box .button-group.clear.success .button.disabled:focus, .f-box .button-group.clear.success .button[disabled]:focus, .f-box .button-group.clear .button.success, .f-box .button-group.clear .button.success.disabled, .f-box .button-group.clear .button.success[disabled], .f-box .button-group.clear .button.success.disabled:hover, .f-box .button-group.clear .button.success[disabled]:hover, .f-box .button-group.clear .button.success.disabled:focus, .f-box .button-group.clear .button.success[disabled]:focus {
      color: #3adb76; }
    .f-box .button-group.clear.success .button:hover, .f-box .button-group.clear.success .button:focus, .f-box .button-group.clear .button.success:hover, .f-box .button-group.clear .button.success:focus {
      color: #157539; }
    .f-box .button-group.clear.warning .button, .f-box .button-group.clear.warning .button.disabled, .f-box .button-group.clear.warning .button[disabled], .f-box .button-group.clear.warning .button.disabled:hover, .f-box .button-group.clear.warning .button[disabled]:hover, .f-box .button-group.clear.warning .button.disabled:focus, .f-box .button-group.clear.warning .button[disabled]:focus, .f-box .button-group.clear .button.warning, .f-box .button-group.clear .button.warning.disabled, .f-box .button-group.clear .button.warning[disabled], .f-box .button-group.clear .button.warning.disabled:hover, .f-box .button-group.clear .button.warning[disabled]:hover, .f-box .button-group.clear .button.warning.disabled:focus, .f-box .button-group.clear .button.warning[disabled]:focus {
      color: #ffae00; }
    .f-box .button-group.clear.warning .button:hover, .f-box .button-group.clear.warning .button:focus, .f-box .button-group.clear .button.warning:hover, .f-box .button-group.clear .button.warning:focus {
      color: #805700; }
    .f-box .button-group.clear.alert .button, .f-box .button-group.clear.alert .button.disabled, .f-box .button-group.clear.alert .button[disabled], .f-box .button-group.clear.alert .button.disabled:hover, .f-box .button-group.clear.alert .button[disabled]:hover, .f-box .button-group.clear.alert .button.disabled:focus, .f-box .button-group.clear.alert .button[disabled]:focus, .f-box .button-group.clear .button.alert, .f-box .button-group.clear .button.alert.disabled, .f-box .button-group.clear .button.alert[disabled], .f-box .button-group.clear .button.alert.disabled:hover, .f-box .button-group.clear .button.alert[disabled]:hover, .f-box .button-group.clear .button.alert.disabled:focus, .f-box .button-group.clear .button.alert[disabled]:focus {
      color: #cc4b37; }
    .f-box .button-group.clear.alert .button:hover, .f-box .button-group.clear.alert .button:focus, .f-box .button-group.clear .button.alert:hover, .f-box .button-group.clear .button.alert:focus {
      color: #67251a; }
    .f-box .button-group.no-gaps .button {
      margin-right: -0.0625rem; }
      .f-box .button-group.no-gaps .button + .button {
        border-left-color: transparent; }
    .f-box .button-group.stacked, .f-box .button-group.stacked-for-small, .f-box .button-group.stacked-for-medium {
      flex-wrap: wrap; }
      .f-box .button-group.stacked .button, .f-box .button-group.stacked-for-small .button, .f-box .button-group.stacked-for-medium .button {
        flex: 0 0 100%; }
        .f-box .button-group.stacked .button:last-child, .f-box .button-group.stacked-for-small .button:last-child, .f-box .button-group.stacked-for-medium .button:last-child {
          margin-bottom: 0; }
      .f-box .button-group.stacked.expanded .button, .f-box .button-group.stacked-for-small.expanded .button, .f-box .button-group.stacked-for-medium.expanded .button {
        flex: 1 1 0px; }
    @media print, screen and (min-width: 40em) {
      .f-box .button-group.stacked-for-small .button {
        flex: 0 0 auto;
        margin-bottom: 0; } }
    @media print, screen and (min-width: 64em) {
      .f-box .button-group.stacked-for-medium .button {
        flex: 0 0 auto;
        margin-bottom: 0; } }
    @media print, screen and (max-width: 39.99875em) {
      .f-box .button-group.stacked-for-small.expanded {
        display: block; }
        .f-box .button-group.stacked-for-small.expanded .button {
          display: block;
          margin-right: 0; } }
    @media print, screen and (max-width: 63.99875em) {
      .f-box .button-group.stacked-for-medium.expanded {
        display: block; }
        .f-box .button-group.stacked-for-medium.expanded .button {
          display: block;
          margin-right: 0; } }
  .f-box .close-button {
    position: absolute;
    z-index: 10;
    color: #8a8a8a;
    cursor: pointer; }
    [data-whatinput='mouse'] .f-box .close-button {
      outline: 0; }
    .f-box .close-button:hover, .f-box .close-button:focus {
      color: #0a0a0a; }
    .f-box .close-button.small {
      right: 0.66rem;
      top: 0.33em;
      font-size: 1.5em;
      line-height: 1; }
    .f-box .close-button, .f-box .close-button.medium {
      right: 1rem;
      top: 0.5rem;
      font-size: 2em;
      line-height: 1; }
  .f-box .switch {
    position: relative;
    margin-bottom: 1rem;
    outline: 0;
    font-size: 0.875rem;
    font-weight: bold;
    color: #fefefe;
    user-select: none;
    height: 2rem; }
  .f-box .switch-input {
    position: absolute;
    margin-bottom: 0;
    opacity: 0; }
  .f-box .switch-paddle {
    position: relative;
    display: block;
    width: 4rem;
    height: 2rem;
    border-radius: 0;
    background: #cacaca;
    transition: all 0.25s ease-out;
    font-weight: inherit;
    color: inherit;
    cursor: pointer; }
    input + .f-box .switch-paddle {
      margin: 0; }
    .f-box .switch-paddle::after {
      position: absolute;
      top: 0.25rem;
      left: 0.25rem;
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      transform: translate3d(0, 0, 0);
      border-radius: 0;
      background: #fefefe;
      transition: all 0.25s ease-out;
      content: ''; }
    input:checked ~ .f-box .switch-paddle {
      background: #1779ba; }
      input:checked ~ .f-box .switch-paddle::after {
        left: 2.25rem; }
    input:disabled ~ .f-box .switch-paddle {
      cursor: not-allowed;
      opacity: 0.5; }
    [data-whatinput='mouse'] input:focus ~ .f-box .switch-paddle {
      outline: 0; }
  .f-box .switch-active, .f-box .switch-inactive {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  .f-box .switch-active {
    left: 8%;
    display: none; }
    input:checked + label > .f-box .switch-active {
      display: block; }
  .f-box .switch-inactive {
    right: 15%; }
    input:checked + label > .f-box .switch-inactive {
      display: none; }
  .f-box .switch.tiny {
    height: 1.5rem; }
    .f-box .switch.tiny .switch-paddle {
      width: 3rem;
      height: 1.5rem;
      font-size: 0.625rem; }
    .f-box .switch.tiny .switch-paddle::after {
      top: 0.25rem;
      left: 0.25rem;
      width: 1rem;
      height: 1rem; }
    .f-box .switch.tiny input:checked ~ .switch-paddle::after {
      left: 1.75rem; }
  .f-box .switch.small {
    height: 1.75rem; }
    .f-box .switch.small .switch-paddle {
      width: 3.5rem;
      height: 1.75rem;
      font-size: 0.75rem; }
    .f-box .switch.small .switch-paddle::after {
      top: 0.25rem;
      left: 0.25rem;
      width: 1.25rem;
      height: 1.25rem; }
    .f-box .switch.small input:checked ~ .switch-paddle::after {
      left: 2rem; }
  .f-box .switch.large {
    height: 2.5rem; }
    .f-box .switch.large .switch-paddle {
      width: 5rem;
      height: 2.5rem;
      font-size: 1rem; }
    .f-box .switch.large .switch-paddle::after {
      top: 0.25rem;
      left: 0.25rem;
      width: 2rem;
      height: 2rem; }
    .f-box .switch.large input:checked ~ .switch-paddle::after {
      left: 2.75rem; }
  .f-box .button {
    background-color: #24c1a9; }
  .f-box .button:hover, .f-box .button:focus {
    background-color: #1ea48f;
    color: #fefefe; }
  .f-box input.has-errors {
    border: 1px solid #ff5252; }
  .f-box .help-text {
    color: #EEECEC; }
    .f-box .help-text.error {
      margin-top: -15px;
      font-size: 10px;
      line-height: 1.3; }
    .f-box .help-text.info {
      margin-top: -15px;
      font-size: 12px; }
  .f-box .help-text.info {
    background-color: #f38012 !important;
    border-color: #f38012 !important;
    color: #EEECEC; }
    .f-box .help-text.info a {
      color: #EEECEC; }
  .f-box p {
    margin-bottom: 1px; }
  .f-box select {
    width: 100%; }
  .f-box .grid-padding-x > .cell {
    padding-right: 0.2675rem;
    padding-left: 0.2675rem; }
