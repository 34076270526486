@charset "utf-8";

@import "../../../node_modules/foundation-sites/scss/foundation";


.f-box {
    //@include foundation-global-styles;
    @include foundation-xy-grid-classes;
    //@include foundation-grid;
    //@include foundation-flex-grid;
    //@include foundation-flex-classes;
    //@include foundation-typography;
    @include foundation-forms;
    @include foundation-button;
    //@include foundation-accordion;
    //@include foundation-accordion-menu;
    //@include foundation-badge;
    //@include foundation-breadcrumbs;
    @include foundation-button-group;
    //@include foundation-callout;
    //@include foundation-card;
    @include foundation-close-button;
    //@include foundation-menu;
    //@include foundation-menu-icon;
    //@include foundation-drilldown-menu;
    //@include foundation-dropdown;
    //@include foundation-dropdown-menu;
    //@include foundation-responsive-embed;
    //@include foundation-label;
    //@include foundation-media-object;
    //@include foundation-off-canvas;
    //@include foundation-orbit;
    //@include foundation-pagination;
    //@include foundation-progress-bar;
    //@include foundation-slider;
    //@include foundation-sticky;
    //@include foundation-reveal;
    @include foundation-switch;
    //@include foundation-table;
    //@include foundation-tabs;
    //@include foundation-thumbnail;
    //@include foundation-title-bar;
    //@include foundation-tooltip;
    //@include foundation-top-bar;
    //@include foundation-visibility-classes;
    //@include foundation-float-classes;



    /* Overides - Note: In future we want to load following in a modular way, also namespace colors */
    .button {
        background-color: #24c1a9;
    }

    .button:hover, .button:focus {
        background-color: #1ea48f;
        color: #fefefe;
    }

    /* Helper to apply light color text with dark background */
    input {
        &.has-errors {
            border: 1px solid #ff5252;
        }
    }

    .help-text {
        color: #EEECEC;

        &.error {
            margin-top: -15px;
            font-size: 10px;
            line-height: 1.3;
        }

        &.info {
            margin-top: -15px;
            font-size: 12px;
        }
    }

    .help-text.info {
        background-color: #f38012 !important;
        border-color: #f38012 !important;
        color: #EEECEC;
        a {
            color: #EEECEC;
        }
    }

    p {
        margin-bottom: 1px;
    }

    /* Foundation "select" tags have blank space without setting the width, this lets it take up any empty space */
    select {
        width: 100%;
    }

    /* GRID TODO: See if we can set this through a mixin foundation provides */
    .grid-padding-x > .cell {
        padding-right: 0.2675rem;
        padding-left: 0.2675rem;
    }
}


